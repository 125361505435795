import React, { useEffect } from 'react'
import { Router, navigate } from '@reach/router'

import { PublicRoute } from 'src/components/auth/publicRoute'
import { AuthWrapper } from 'src/components/auth/authWrapper'
// @ts-ignore
import Activate from 'src/components/auth/activate'

import { ForgotPassword } from 'src/components/auth/forgotPassword'
import { Register } from 'src/components/auth/register'
import { Login } from 'src/components/auth/login'
import { Reset } from 'src/components/auth/reset'
import { InvalidToken } from '../components/auth/invalid_token'
import { Portal } from '../components/auth/portal'
import { ToyStore } from '../components/auth/toyStore'
import { Subscriptions } from '../components/auth/subscriptions'

import { useSetPage } from 'src/context/siteContext'

const Account = ({
  pageContext
}: {
  pageContext: {}
}) => {
  const setPage = useSetPage()
  useEffect(() => {
    setPage('accounts')
  }, [0])
  return (
    <div id='account__container' className='accounts__route bcblue'>
        <React.Fragment>
          <div className='nav-spacer' />
        </React.Fragment>
      <Router>
        <PublicRoute path='/account'>
          <AuthWrapper path='/' component={Portal} />
          <AuthWrapper path='/subscriptions' component={Subscriptions} />
          <ToyStore path='/toy-store' />
          <Reset path='/reset/:id/:token' />
          <Login path='/login' />
          <Activate
            // @ts-ignore
            path='/activate/:id/:token'
          />
          <InvalidToken path='/invalid_token' />
          <Register path='/register' />
          <ForgotPassword path='/forgot' />
        </PublicRoute>
      </Router>
    </div>
  )
}

export default Account
