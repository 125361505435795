import React, { useEffect} from 'react'
import cx from 'classnames'

import { TicketController } from 'src/components/auth/ticketController'
import { KeyNavigation } from 'src/components/auth/keyNavigation'

export const PublicRoute = (props: { children: React.ReactNode; path: string }) => {
  return (
    <div className='p1'>
      <div className='container--xl account__auth-inner mxa outer py1'>
        <div className='account__vending jcb aist'>
          <div className={cx('account__screen x bcb  rel jcc aic cw', {
            'f unauth': /login|reset|activate|forgot|register/.test(props.location.pathname)
          })}>
            <div className='account__tickets abs x y top left z1'>
              <img className='account__tickets-top abs ' src='/tickets.png' alt='ticket images' />
              <img className='account__tickets-bottom abs ' src='/tickets.png' alt='ticket images' />
            </div>
            <div className='account__screen-inner rel z2'>
              <img src='/stars_1.png' className='abs top left x y star__background' />
              <img src='/stars_2.png' className='abs top left x y star__background_2' />
              <div className='rel z1'>{props.children}</div>
            </div>
          </div>
          <div className='account__vending-side jcb aic p1'>
            <TicketController authed={/login|reset|activate|forgot|register/.test(props.location.pathname)} />
            <KeyNavigation path={props.location.pathname} />
          </div>
        </div>
        <div className='account__mobile'>
          <KeyNavigation path={props.location.pathname} />
        </div>
      </div>
    </div>
  )
}