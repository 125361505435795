import React, { useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import fetch from 'unfetch'
import { encode } from 'shopify-gid'
import { useLoads } from 'react-loads'
import { Link, navigate } from 'gatsby'
import PasswordValidator from 'password-validator'
import Timeout from 'await-timeout'

import { UpdateCustomer } from "../../utils/updateCustomer"

export const Reset = (props: {
  path: string
  id?: string
  token?: string
}) => {
  const [passwordField1, setPasswordField1] = useState("")
  const [passwordField2, setPasswordField2] = useState("")
  const [submit, setSubmitting] = useState(false)
  const [formSuccess, setFormSucces] = useState(null)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const schema = new PasswordValidator()

  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .lowercase()
    .has()
    .uppercase()

  const handleReset = useCallback(
    async (password) =>  {

      if (!schema.validate(passwordField1)) {
        throw new Error(
          "Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter."
        )
      }

      if (passwordField1 !== passwordField2) {
        await Timeout.set(400)
        throw new Error("Passwords do not match.")
      }
        fetch(`/.netlify/functions/reset-password`, {
          method: 'POST',
          body: JSON.stringify({
            id: encode('Customer', props.id),
            input: {
              resetToken: props.token,
              password
            }
          })
        })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              throw new Error(res.error)
              setSubmitting(false)
            } else {
              setFormSucces(true)
              // UpdateCustomer(res, res.customer.email)
              // re-hydrate the cart so it contains the email
              // checkout.hydrate()
              setTimeout(() => {
                navigate('/account/login')
              }, 400)
            }
          })
        },
    [passwordField1, passwordField2]
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleReset",
    handleReset as any,
    {
      defer: true
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    const { password } = form.current.elements
    load(password.value)
  }
  return (
    <div>
      <Helmet title='reset' />
      <div className="nav-spacer" />
      <div className="accounts__wrapper f col jcc outer aic y px1">
        <div className='container--xl ac x ma'>
          <form className='f col jcc aic y' onSubmit={e => handleSubmit(e)} ref={form}>
            <div className="container--xl mya ac">

              <h4 className="pb0 caps h2 sh4 offlimits sans ls">Reset Password</h4>
              <p className="">Let's get you logged back in.</p>
            </div>
            {(isPending ||
              isReloading) && (
              <span>Loading</span>
            )}

            {isRejected && (
              <div className="studio mt1 error">
                <span role="img" aria-label="error">
                  ⚠️
                </span>
                : {error.message}
              </div>
            )}
            <div className="container--s al mxa x">
              {formSuccess && (
                <div className='small mt1'>Got it! Email coming your way now.</div>
              )}
              <div className="pb1  x pya">
                <label className="caps sans ls mt1">Password</label>
                <input name='password' type='password'  value={passwordField1} onChange={e => setPasswordField1(e.target.value)} required={true} className='accounts__input cw px1 py1 s16 x' placeholder='Password' />
              </div>
              <div className="pb05 x pya">
                <label className="caps sans ls py05 mt1">Confirm Password</label>
                <input name='passwordConfirm' type='password' value={passwordField2} onChange={e => setPasswordField2(e.target.value)}  required={true} className='accounts__input cw mb1 px1 py1 s16 x' placeholder='Confirm Password' />
              </div>
              <div className="ac x">
                <button type="submit" className="button button--wide cg ac akz ls-s button--red box-shadow-sm s20">
                  <span className='cb'>{submit ? 'Resetting' : 'Reset Password'}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
