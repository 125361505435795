import React, { useEffect, useState } from 'react'
import { Subscription } from './subscription'
import axios from 'axios'

export const SubscriptionsList = (props: any) => {
  const {customer} = props
  const [subscriptions, setSubscriptions] = useState(null)

  useEffect(() => {
    subscriptions === null && getSubscriptions()
  }, [0])

  const getSubscriptions = () => {
    // FIXME: this should not be happening customer is an object or array....
    if (customer && customer.id) {
      axios.get(`/.netlify/functions/recharge-subscriptions?customerId=${customer.id}`)
        .then(({data: subscriptions}) =>  setSubscriptions(subscriptions))
    }

    if (customer[0]) {
      axios.get(`/.netlify/functions/recharge-subscriptions?customerId=${customer[0].id}`)
        .then(({data: subscriptions}) =>  setSubscriptions(subscriptions))
    }
  }

  return (
    <div className="subscriptions">
      { subscriptions && subscriptions.map((subscription:any)  =>
        <Subscription key={subscription.id} subscription={subscription} getSubscriptions={getSubscriptions} />
      )}
      {!subscriptions && (
        <div>
          <h4 className='mt1'>Currently no subscriptions</h4>
          <img className='p1 x sad-face mt1' src='/zombie_sad.png' alt='sad zombie' />
        </div>
      )}
    </div>
  )
}