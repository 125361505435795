import React, {useState} from 'react'
import axios from 'axios'
import spacetime from 'spacetime'

export const SubscriptionsConfirmations = (props: any) => {
  const { subscription, getSubscriptions, setActiveAction, activeAction } = props
  const [sendingInformation, setSendingInformation] = useState('')
  const [cancelReason, setCancelReason] = useState("")

  const getNextDeliveryDate = () => {
    const nextDate = new Date(subscription.next_charge_scheduled_at)
    nextDate.setMonth( nextDate.getMonth() + 1 )
    return nextDate;
  }

  const skipNextDelivery = () => {
    setSendingInformation('skip')
    const nextDate = getNextDeliveryDate()
    axios.post(`/.netlify/functions/recharge-subscriptions-skip`,
      {
        nextDate,
        subscription,
      }
    )
    .then(() => {
      setSendingInformation('')
      setActiveAction('')
      getSubscriptions()
    })
    .catch(() => {
      setActiveAction('')
      setSendingInformation('')
    })
  }

  const cancelSubscription = () => {
    setSendingInformation('cancel')
    axios.post(`/.netlify/functions/recharge-subscriptions-cancel`,
      {
        subscription,
        cancelReason,
      }
    )
    .then(() => {
      setSendingInformation('')
      setActiveAction("")
      getSubscriptions()
    })
    .catch(() => {
      setActiveAction('')
      setSendingInformation('')
    })
  }

  return activeAction === 'skip' || activeAction === 'cancel'  ? (
    <div className="subs-bottom sub-panel smaller tal s16">
    { sendingInformation === 'skip' &&
      "We are changing your next order date..."
    }
    { sendingInformation === 'cancel' &&
      "We are canceling your next order..."
    }
    {
      activeAction === 'skip' && sendingInformation === '' &&
      <div className="sub-actions">
        <div className="sub-actions-text al">
          <div>Are you sure you want to skip next month?</div>
          <div>Next charge will be on {spacetime(getNextDeliveryDate()).unixFmt('MMMM dd, yyyy')}</div>
        </div>
        <div className="sub-buttons">
          <button className="button button--red button--black-text small" onClick={skipNextDelivery}>Yes</button>
          <button className="button button--red button--black-text small" onClick={() => setActiveAction('')}>No</button>
        </div>
      </div>
    }

    {
      activeAction === 'cancel' && sendingInformation === '' &&
      <div className="sub-actions">
        <div className="sub-actions-text al">
          <div>Are you sure you want to cancel your subscription?</div>
          <div className="text-area">
            <textarea className="bcw" value={cancelReason} onChange={event => setCancelReason(event.currentTarget.value)} placeholder="Let us know why you are canceling to help us give you a better service..."/>
          </div>
        </div>
        <div className="sub-buttons">
          <button className="button button--red button--black-text small" onClick={cancelSubscription}>Yes</button>
          <button className="button button--red button--black-text small" onClick={() => setActiveAction('')}>No</button>
        </div>
      </div>
    }
    </div>
  ) : null
}