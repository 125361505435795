import React, { Fragment, useState } from 'react'
import { SubscriptionActions } from './subscriptionActions'
import { SubscriptionsConfirmations } from './actions/subscriptionsConfirmations'
import spacetime from 'spacetime'
import { useStore } from 'src/context/siteContext'
import { Image } from 'src/components/image'
import ImgZombieSad from 'static/zombie_sad.png'
import { ToysSection } from './actions/toys'

export interface SubscriptionProps {
  id: number,
  product_title: string,
  next_charge_scheduled_at: string,
  status: string,
  onetimes: [],
  shopify_product_id: number,
  quantity: number,
}

export interface Product {
  _id: number
}

export const Subscription = ({ subscription, getSubscriptions }: { subscription: SubscriptionProps, getSubscriptions: any }) => {
  const store = useStore()
  const {products} = store
  const {next_charge_scheduled_at} = subscription
  const [activeAction, setActiveAction] = useState('')

  const getProduct = (productId: number) => {
    return products.find((product: Product) => {
      return product._id === productId.toString()
    })
  }

  const product: any = getProduct(subscription.shopify_product_id)

  return subscription.status === 'CANCELLED' ? null : (
    <Fragment>
      <div className="sub-item sub-panel f">
        <div className="item-description">
          { product &&
            <div className="item-block">
              <Image className='x toy__store-image mxa' imageId={product.content.main.mainImage.asset._ref} alt={product.content.main.title} />
              <div className="al">
                <div>
                  <h4 className="offlimits caps">{product.content.main.title}</h4>
                  <div className="caps ls1 founders bold s14">{product.content.main.subTitle} Cereal</div>
                  <div className="caps ls1 founders bold s14">Set of {2 * subscription.quantity} </div>
                  <div className="caps ls1 founders bold s14 tickets">+ {product.content.main.ticketValue * 2} tickets <img src='/mini-ticket.png' className="ml05 ticket" alt='ticket image' /></div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="item-info al s16">
          <div className="bbd bdcw mb05 pb05">Delivered Every Month</div>
          <div className="mb05">Next Charge: {spacetime(next_charge_scheduled_at).unixFmt('MMMM dd, yyyy')}</div>
          <div>
            <SubscriptionActions subscription={subscription} getSubscriptions={getSubscriptions} setActiveAction={setActiveAction}/>
          </div>
        </div>
        <SubscriptionsConfirmations subscription={subscription} getSubscriptions={getSubscriptions} setActiveAction={setActiveAction} activeAction={activeAction} />
        { subscription.onetimes.length > 0 &&
          <div className='subs-bottom'>
              { subscription.onetimes.map( (onetime: any) => {
                const oneTimeProduct: any = getProduct(onetime.shopify_product_id)
                if (oneTimeProduct) {
                  return (
                    <div className="sub-panel smaller f" key={onetime.id}>
                      <div>
                        <div className="al b">
                          {oneTimeProduct.content.main.title}
                        </div>
                        <div className="f aic caps b">
                          - Tickets {oneTimeProduct.content.main.ticketValue} <img src='/mini-ticket.png' className="ml05 ticket" alt='ticket image' />
                        </div>
                      </div>
                      <Image className='x toy__store-image mxa' imageId={oneTimeProduct.content.main.mainImage.asset._ref} alt={oneTimeProduct.content.main.title} />
                    </div>
                  )
                } else {
                  return null
                }
              })}
          </div>
        }
        { activeAction === '' &&
          <div className="subs-bottom sub-panel smaller al f jcb aic s16">
            Add a toy to next month's delivery
            <button className="button button--red button--black-text round" onClick={() => setActiveAction('toys')}>+</button>
          </div>
        }
      </div>
      {
        activeAction === 'cancel' &&
        <div className="cancel-adventure">
          <img src={ImgZombieSad} />
          <h2 className="offlimits caps">I guess good luck on your cereal adventure?</h2>
        </div>
      }
      {
        activeAction === 'toys' &&
        <div className="subs-bottom sub-panel al toys">
          <ToysSection setActiveAction={setActiveAction} subscription={subscription} getSubscriptions={getSubscriptions}/>
        </div>
      }
    </Fragment>
  )
}