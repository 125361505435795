import React, { useCallback, useEffect } from "react"
import fetch from "unfetch"
import { Link, navigate } from "gatsby"
import Helmet from "react-helmet"

import { decode } from 'shopify-gid'
import { useLoads } from "react-loads"
import cx from "classnames"
import cookie from "js-cookie"

import { UpdateCustomer } from "../../utils/updateCustomer"

import { useTickets } from 'src/context/siteContext'

export const Login = ({ path }: { path: string }) => {
  const form = React.createRef() as React.RefObject<HTMLFormElement>
  const updateCustomerTickets = useTickets()

  const handleLogin = useCallback(
    (email, password) =>
      fetch(`/.netlify/functions/login`, {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw new Error(res.error)
          } else {
            UpdateCustomer(res, email)
            cookie.set("customer_id", parseInt(decode(res.customer.id).id, 10), { expires: 25 })
            // re-hydrate the cart so it contains the email
            // checkout.hydrate()
            setTimeout(() => {
              updateCustomerTickets()
              navigate("/account/toy-store")
            }, 400)
            return null
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleLogin",
    handleLogin as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { email, password } = form.current.elements

    load(email.value, password.value)
  }

  useEffect(() => {
    const customerEmail = cookie.get("customer_email")
    if (customerEmail) {
      navigate("/account/toy-store")
    }
  }, [0])

  return (
    <div className=''>
      <Helmet title="login" />
      <div className="accounts__wrapper f col jcc aic y px1 outer">
        <form
          className={cx("f col jcc x aic y")}
          onSubmit={e => handleSubmit(e)}
          ref={form}
        >
          <div className="container--m mya ac">
            <h4 className="pb0 caps h2 sh4 offlimits sans ls">Trade your tickets in for super cool toys</h4>
            <p>Log in to redeem your toys</p>
          </div>

          <div className="x container--s al mya">
            {(isPending ||
              isReloading) && (
              <span>Loading</span>
            )}

            {isRejected && (
              <div className="studio mt1 error">
                <span role="img" aria-label="error">
                  ⚠️
                </span>
                : {error.message}
              </div>
            )}
            <div className="pb1 pya cw">
              <label className="caps sans s14 ls my05">Email</label>
              <input name="email" type="text" required={true} className="accounts__input cw py1 px1 s16 x" placeholder="Email" />
            </div>
            <div className="pb05 pya">
              <label className="caps sans s14 ls mt01 py05">Password</label>
              <input name="password" type="password" required={true} className="accounts__input cw py1 px1 mb1 s16 x" placeholder="Password" />
            </div>
            <div className="caps sans ls"/>
            <div className="x mxa ac">
              <button type="submit" className="button box-shadow-sm button--wide button--red ac s20">
                {(isPending ||
                  isReloading) ? (
                  <span className='cb'>Loading</span>
                ): (
                  <span className='cb'>Log in</span>
                )}
              </button>
            </div>
          </div>

          <div className="container-m mya aic">
            <div className="ac mt1 pt1">
              <Link className="s14 underline active" to="/account/forgot">
                Forgot password?
              </Link>
            </div>
            <div className="ac s14">
              Don't have an account?{" "}
              <Link className="underline active" to="/account/register">
                Sign up
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
