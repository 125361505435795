import React, { Fragment } from 'react'

export const SubscriptionActions = (props: any) => {
  const {setActiveAction} = props

  return (
    <div className="sub-cta">
      <Fragment>
        <button className="button button--red button--black-text" onClick={() => setActiveAction('skip')}>Skip next delivery</button>
        <button className="button button--red button--black-text" onClick={() => setActiveAction('cancel')}>Cancel subscription</button>
      </Fragment>
    </div>
  )
}