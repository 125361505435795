import React, { Fragment, useEffect } from 'react'
import { useStore } from 'src/context/siteContext'
import { SubscriptionsList} from './subscriptions/subscriptionsList'

import { useRechargeCustomer } from 'src/context/siteContext'

export const Subscriptions = () => {
  const store = useStore()
  const updateRechargeCustomer = useRechargeCustomer()
  const {rechargeCustomer: customer} = store
  useEffect(() => {
    updateRechargeCustomer()
  }, [])
  return (
    <div className='container--l mxa p1 outer ac subs-container'>
      { customer &&
        <Fragment>
          <h2 className="offlimits caps">MY SUBSCRIPTIONS</h2>
          <SubscriptionsList customer={customer} />
        </Fragment>
      }
    </div>
  )
}