import React, { useState, useEffect} from 'react'

import { Link } from 'gatsby'
import cx from 'classnames'

export const KeyNavigation = ({ path }) => {
  const [activePath, setActive] = useState(path)
  useEffect(() => {
    setActive(path)
  }, [path])
  return (
    <div className='account__nav p1 x'>
      <div className='f jcb aic x'>
        <Link to='/account/toy-store' className={cx('caps founders', {
          'active bcw': /toy/.test(activePath)
        })}>Toys</Link>
        <Link to='/account/subscriptions' className={cx('caps founders', {
          'active bcw': /subscription/.test(activePath)
        })}>Subscriptions</Link>
        <Link to='/account' className={cx('caps founders', {
          'active bcw': !/toy|subscription/.test(activePath)
        })}>Account</Link>
      </div>
      <div className='f jcc aic mt pt1 x'>
        <div className={cx('account__dial f jcc aic x rel', {
          'toys': /toy/.test(activePath),
          'account': !/toy|subscription/.test(activePath)
        })}>
          <svg className='account__dial-key abs bottom' width='13' height='39' viewBox='0 0 13 39' fill='none'>
            <rect x='0.273438' y='33.998' width='12' height='4' fill='black'/>
            <rect x='0.273438' y='22.998' width='11' height='4' fill='black'/>
            <rect x='1.27344' y='13.998' width='10' height='4' fill='black'/>
            <rect x='5.27344' y='0.998047' width='17' height='4' transform='rotate(90 5.27344 0.998047)' fill='black'/>
            <rect x='11.2734' y='13.998' width='13' height='4' transform='rotate(90 11.2734 13.998)' fill='black'/>
            <rect x='4.27344' y='22.998' width='16' height='4' transform='rotate(90 4.27344 22.998)' fill='black'/>
          </svg>
        </div>
      </div>
    </div>
  )
}