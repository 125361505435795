
import React, { useState, Fragment } from 'react'
import { useStore, useUpdateTickets } from 'src/context/siteContext'
import { Image } from 'src/components/image'
import axios from 'axios'

export const ToysSection = (props: any) => {
  const {setActiveAction, subscription, getSubscriptions} = props
  const [subscriptionToys, setSubscriptionToys] = useState([])
  const [tickets, setTickets] = useState(0)
  const [orderingToys, setOrderingToys] = useState(false)
  const { toys, customerId } = useStore()

  const updateTickets = useUpdateTickets()

  const addToyToSubscription = (toy: any) => {
    setSubscriptionToys([...subscriptionToys, toy])
    setTickets(tickets + toy.content.main.ticketValue)
  }

  const removeToyFromSubscription = (toy: any) => {
    const newSubscriptionToys = subscriptionToys.filter( item => item._id !== toy._id)
    setSubscriptionToys(newSubscriptionToys)
    setTickets(tickets - toy.content.main.ticketValue)
  }

  interface toy {
    title: string,
    quantity: number,
  }

  const setupToysOrder = () => {
    setOrderingToys(true)
    orderToy(subscriptionToys)
  }

  const orderToy = async (items: toy[]) => {
    if (items.length > 0) {
      axios.post(`/.netlify/functions/recharge-subscriptions-one-times`,
        {
          item: items[0],
          subscription,
          sanityCustomerId: customerId,
        }
      )
      .then(async (response) => {
          items.shift()
          const {tickets} = response.data.sanityCustomer.content.main
          updateTickets(tickets)
          orderToy(items)
        })
      .catch(() => {
        console.log("Something is wrong with your order, please check")
        setOrderingToys(false)
      })
    } else {
      setActiveAction('')
      getSubscriptions()
    }
  }

  return (
    <Fragment>
      <div>
        {
          orderingToys &&
          <div>
            Please wait a sec, we are adding your toys to your next order...
          </div>
        }
        {
          !orderingToys &&
          toys &&
          toys.map((toy: any) => {
            const item = toy.content.main
            const isAdded = subscriptionToys.some(item => item._id === toy._id)
            return (
              <div key={toy._id} className="toy-item">
                <div className="f jcb">
                  <span className="s24">{item.title}</span>
                  <div className="f aic">
                    <span className="sub-panel smallest f aic marginless s24">
                      {item.ticketValue}
                      <img src='/mini-ticket.png' className="ml05 ticket" alt='ticket image' />
                    </span>
                    <span className="ml05">
                      { !isAdded &&
                        <button className="button button--red button--black-text round" onClick={() => addToyToSubscription(toy)}>+</button>
                      }
                      { isAdded &&
                        <button className="button button--red button--black-text round" onClick={() => removeToyFromSubscription(toy)}>-</button>
                      }
                    </span>
                  </div>
                </div>
                { isAdded &&
                  <div className="ar">
                    Added!
                  </div>
                }
              <Image className='x toy__store-image mxa' imageId={item.mainImage.asset._ref} alt={item.title} />
              </div>
            )
          })
        }
      </div>
      { !orderingToys &&
        <div className="sub-buttons f ">
          <button className="button button--red button--black-text" onClick={() => setActiveAction('')}>I don't want toys this time</button>
          <button className="button button--red button--black-text" onClick={() => setupToysOrder()} disabled={Object.keys(subscriptionToys).length < 1}>Add selected to next order</button>
        </div>
      }
    </Fragment>
  )
}