import React, { useState, useCallback } from "react"
import Helmet from "react-helmet"
import fetch from "unfetch"
import { Link } from "gatsby"
import { useLoads } from 'react-loads'

export const ForgotPassword = ({ path }: { path: string }) => {
  const [formSuccess, setFormSuccess] = useState(false)
  const form = React.createRef() as React.RefObject<HTMLFormElement>

  const handleForgot = useCallback(
    (email) =>
      fetch(`/.netlify/functions/forgot-password`, {
        method: "POST",
        body: JSON.stringify({
          email
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw new Error(res.error)
          } else {
            setFormSuccess(true)
          }
        }),
    []
  )

  const { error, isRejected, isPending, isReloading, load } = useLoads(
    "handleForgot",
    handleForgot as any,
    {
      defer: true,
    }
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { email } = form.current.elements
    load(email.value)
  }

  return (
    <div>
      <Helmet title="forgot password" />
      <div className="nav-spacer" />
      <div className="accounts__wrapper cg f jcc px1 outer aic">
        <form className="f col jcc x aic y" onSubmit={e => handleSubmit(e)} ref={form}>
          <div className="container--xl mya ac">
            <div className="m1"/>
            <h4 className="pb0 caps h2 sh4 offlimits sans ls">Forgot Password?</h4>
          </div>

          {isRejected && (
              <div className="studio mt1 error">
                <span role="img" aria-label="error">
                  ⚠️
                </span>
                : {error.message}
              </div>
            )}

          {formSuccess && (
            <div className="small studio mt1">
              Got it! Email coming your way now.
            </div>
          )}

          <div className="x container--s col aic jcc">
            <div className="pt1 mt1 mb05 pya">
              <label className="caps sans ls my05">Email</label>
              <input name="email" type="text" required={true} className="accounts__input py1 px1 x cw s16 mb1" placeholder="Enter Email" />
            </div>
            <div className="x mxa ac">
              <button type="submit" className="button button--wide cg ac akz ls-s button--red box-shadow-sm s20">
                {(isPending ||
                  isReloading) ? (
                  <span className='cb'>Loading</span>
                ): (
                  <span className='cb'>Request Reset</span>
                )}
              </button>
            </div>
          </div>

          <p className="mya pt1 mt1 s14">
            Remember your password?{" "}
            <Link className="underline active" to="/account/login">
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  )
}
